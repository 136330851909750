import { DiscountLine } from '@msdyn365-commerce/retail-proxy';

const getNormalizedDiscountPercentage = (percentage?: number): number => {
    if (!percentage) return 0;
    return percentage / 100;
};

export const getCustomDiscountPercentage = (discountLines?: DiscountLine[]): string | undefined => {
    if (!discountLines || discountLines.length < 1) return;
    const normalizedDiscountedPrice = discountLines.reduce(
        (accumulator, discountLine) => accumulator * (1 - getNormalizedDiscountPercentage(discountLine.Percentage)),
        1
    );
    return `-${100 - Math.round(normalizedDiscountedPrice * 100)}%`;
};
